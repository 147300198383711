body {
  margin: 0 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  /* padding: .5em .5em; */
  box-sizing: border-box; 
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: 'primary.main';
}

a:active {
  color: #d19188;
  text-decoration: underline;
}

a:link {
text-decoration: none;
}


a:hover {
  color: #944342;
  text-decoration: underline;
}

.welcomeTag {
  animation: welcomeTag 5s;
  z-index: 2;
}

@keyframes welcomeTag {
  0%   {bottom:0px;}
  25%  {bottom:100px;}
  50%  {bottom:200px;}
  75%  {bottom:300px;}
  100% {bottom:400px;}
}